import { Parallax } from 'react-parallax';
import { Link } from 'react-router-dom';

import SignUp from './../components/SignUp';
import Section from './../components/Section';

import { isMobileDevice } from './../utilities/isMobileDevice';

import './../css/Home.css';
import square from 'bootstrap-icons/icons/square-fill.svg';

export default function Home() {
  return (
    <div className="landing-container">
      <Section ariaLabel="Title Card">
        <div className="title-container">
          <h1>All your shows.</h1>
          <h1>All in one place.</h1>
          <a href="#sign-up" className="btn btn-primary-lg title-button shadow-sm">
            Sign up for free!
          </a>
        </div>
      </Section>
      <Section>
        <h1>Concert planning made simple</h1>
        {!isMobileDevice() && (
          <Parallax
            bgImage={square}
            bgImageStyle={{
              width: '15em',
              top: '2em',
            }}
            strength={50}
          >
            <i className="bi bi-calendar-week calendar-color" style={{ fontSize: '12em', margin: '0.5em' }} />
          </Parallax>
        )}
        <p className="section-paragraph">Craft your perfect concert crew with a simple click of a button.</p>
      </Section>
      <Section style={{ borderBottom: '1rem solid #EFEFEF' }}>
        <h1>Each ticket, tailored to you</h1>
        {!isMobileDevice() && (
          <Parallax
            bgImage={square}
            bgImageStyle={{
              width: '15em',
              top: '2em',
            }}
            strength={50}
          >
            <i className="bi bi-body-text body-text-color" style={{ fontSize: '12em', margin: '0.5em' }} />
          </Parallax>
        )}
        <p className="section-paragraph">
          Never forget the small details by adding customized notes to your activities.
        </p>
      </Section>
      <Section
        style={{
          backgroundColor: '#151035',
          color: '#F5F8FB',
          borderWidth: '0.5rem',
          borderColor: '#00D1B8',
          paddingBottom: '2rem',
        }}
      >
        <h1 style={{ textAlign: 'left', paddingTop: '2rem' }}>
          We're making concert tracking as <span style={{ color: '#00D1B8' }}>easy</span> as possible.
        </h1>
        <Link
          to="about"
          className="about-us-button"
          style={{ borderWidth: '1px', borderColor: '#00D1B8', padding: '1rem', marginTop: '1rem' }}
        >
          Learn more{' '}
          <span>
            <i className="bi bi-arrow-right" />
          </span>
        </Link>
      </Section>
      <Section style={{ borderWidth: '1rem', borderColor: '#EFEFEF' }} ariaLabel="Sign Up">
        <SignUp />
      </Section>
    </div>
  );
}
