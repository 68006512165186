import './../../css/Header.css';

export default function Header() {
  return (
    <div className="flex items-center sticky top-0 h-[10vh] justify-between bg-[#F5F8FB] px-32 z-[1] shadow-sm">
      <a
        className="btn btn-lg"
        href="/"
        style={{
          display: 'flex',
          alignItems: 'center',
          fontVariantCaps: 'all-small-caps',
          fontSize: '1.5rem',
        }}
      >
        Showboat
      </a>
      <div>
        <a href="#sign-up" className="btn title-button shadow-sm mt-0">
          Sign up
        </a>
      </div>
    </div>
  );
}
