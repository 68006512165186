import { useState } from 'react';
import Alert from './Alert';
import '../css/signup.css';

export default function SignUp() {
  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [email, setEmail] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [consentCheckbox, setConsentCheckbox] = useState(false);
  const [alertType, setAlertType] = useState('');

  const isValidEmail = (email: string): boolean => {
    return EMAIL_REGEX.test(email);
  };

  const clearForm = () => {
    setEmail('');
    setConsentCheckbox(false);
  };

  const showSuccessMessage = () => {
    setAlertType('success');
    setPopupMessage('Success! Keep an eye out for an email from us!');
    clearForm();
  };

  const showErrorMessage = () => {
    setAlertType('danger');
    setPopupMessage('Something went wrong. Please try again.');
  };

  const signup = async () => {
    try {
      // Prepare the data to be sent in the POST request
      const date = new Date();
      const formData = {
        email: email,
        date: date,
      };

      const res = await fetch('/api/v1/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Convert formData to JSON
        signal: AbortSignal.timeout(2000), // Set a timeout of 2 seconds
      });
      const result = await res.json();
      console.log(result); // Store the server response in state

      if (res.status !== 200) {
        throw new Error(`Non-200 response. Result: ${JSON.stringify(result)}`);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error response from POST /api/v1/signup:', error.message);
        throw error;
      } else {
        console.error('Unknown error occurred');
        throw new Error('Unknown error occurred');
      }
    }
  };

  const sendEmail = async () => {
    try {
      // // Prepare the data to be sent in the POST request
      // const date = new Date();
      // const formData = {
      //   email: email,
      //   date: date,
      // };
      // // Make the POST request using fetch
      // const res = await fetch('/api/v1/signup/email', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(formData), // Convert formData to JSON
      //   signal: AbortSignal.timeout(2000), // Set a timeout of 2 seconds
      // });
      // const result = await res.json();
      // console.log(result); // Store the server response in state
      // if (res.status !== 200) {
      //   throw new Error(`Non-200 response: \n status: ${result.status} \n message: ${result.message}`);
      // }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error response from POST /api/v1/signup/email:', error.message);
        throw error;
      } else {
        console.error('Unknown error occurred');
        throw new Error('Unknown error occurred');
      }
    }
  };

  const handleEmailInputChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleConsentCheckboxChange = () => {
    setConsentCheckbox((prevState) => !prevState);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // Check if error occurs
    if (!isValidEmail(email)) {
      setAlertType('danger');
      setPopupMessage('Please ensure that you have provided a valid email address.');
      return;
    }
    if (!consentCheckbox) {
      setAlertType('danger');
      setPopupMessage('Please fill out the form and check that you have accepted to receive emails.');
      return;
    }

    try {
      await signup();
      await sendEmail();
      showSuccessMessage();
    } catch (e) {
      console.error('Error during sign-up:', e);
      showErrorMessage();
    }
  };

  return (
    <form id="sign-up" className="sign-up-container">
      {popupMessage && <Alert type={alertType} text={popupMessage} />}
      <h1>All Aboard!</h1>
      <p>Sign up to stay up-to-date on our alpha release!</p>

      <div className="form-floating my-3">
        <input
          type="email"
          id="email"
          className="form-control"
          placeholder="Email"
          aria-label="Email"
          value={email}
          required
          onChange={handleEmailInputChange}
          style={{ width: '30vw' }}
        />
        <label htmlFor="email">Email</label>
      </div>

      <div className="flex gap-2">
        <input
          type="checkbox"
          name="consent-checkbox"
          id="consent-checkbox"
          required
          checked={consentCheckbox}
          onChange={handleConsentCheckboxChange}
        />
        <label htmlFor="consent-checkbox">I agree to receive emails for important news.</label>
      </div>
      <input
        type="submit"
        value="Sign Up!"
        className="btn btn-primary-lg title-button shadow-md"
        onClick={handleSubmit}
      />
    </form>
  );
}
