interface Props {
  type: string;
  text: string;
}

export default function Alert({ type, text }: Props) {
  return (
    <div className={`alert alert-${type}`} role="alert">
      {text}
    </div>
  );
}
