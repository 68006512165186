import './../../css/Footer.css';

interface Props {
  label: string;
  icon?: string;
  link: string;
}

export default function FooterElement({ label, icon = '', link }: Props) {
  return (
    <a href={link} className="footer-element flex btn btn-sm justify-start text-[#F5F8FB] gap-2">
      {icon && <i className={icon} />}
      <p className="footer">{label}</p>
    </a>
  );
}
