import Section from '../components/Section';

export default function AboutUs() {
  return (
    <div>
      <Section
        style={{
          backgroundColor: '#151035',
          color: '#F5F8FB',
          borderWidth: '0.5rem',
          borderColor: '#00D1B8',
          paddingBottom: '2rem',
        }}
      >
        <h1 style={{ textAlign: 'left', paddingTop: '2rem' }}>By music lovers,</h1>
        <h1 style={{ textAlign: 'left', color: '#00D1B8' }}>for music lovers.</h1>
      </Section>
      <Section className="flex p-16" align="start">
        <h1 className="py-2">At Showboat,</h1>
        <p>
          we’re more than just developers — we’re passionate music lovers dedicated to creating a platform that brings
          fans closer to the music they love. Born from our own experiences of chasing unforgettable concerts and
          discovering new artists, we’ve built a concert tracking app designed by music lovers, for music lovers.
        </p>
        <p className="pt-4">
          <span className="underline underline-offset-4 decoration-2 decoration-[#00D1B8]">Our mission</span> is to
          simplify the way you find live events, connect with your favorite artists, and share the magic of live music
          with fellow fans. Because we believe music isn’t just something you listen to —{' '}
          <span className="font-extrabold">it’s something you live for.</span>
        </p>
      </Section>
    </div>
  );
}
